import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "./service.scss";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Data from "./data";
import { FaArrowRightLong } from "react-icons/fa6";
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  { useEffect } from 'react';
import Digital from "../../assests/service/identityservices.webp";
import customer from "../../assests/service/ai.jpg";
import customer_back from "../../assests/service/ai.jpg";
import work from "../../assests/service/infrastructure.jpg";
import digital_back from "../../assests/service/ai.jpg";
import work_back from "../../assests/service/dark.jpg";
import identity from "../../assests/Outsourcing/infra2.jpg";
import application from "../../assests/Outsourcing/application2.jpg";
import staffing from "../../assests/Outsourcing/staffing.jpg";
import identityaccess from "../../assests/Outsourcing/access.jpg";
import Privileged from "../../assests/service/identity.jpg";
// import support from "../../assests/service/support.jpg";
// import support_service from "../../assests/service/dark.jpg";
import eye_one from "../../assests/service/dark.jpg";
import manage from "../../assests/service/dark.jpg";


export default function Service() {

  

  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with animation duration
  }, []);


  const Data = [
    {
      Image: identityaccess,
      ImageTwo:manage,
      Title: "IDENTITY SERVICES",
      Desc: "Encompass CIAM, WIAM, IG, PAM, Digital Identity Transformation, and Managed Support Services. These solutions enhance security, streamline access management, and ensure compliance while providing 24/7 support. Together, they empower organizations to protect sensitive data, optimize operations, and enhance customer and employee experiences in a secure environment.",
      Link: "/digitalidentity-transformation" ,
    },
    {
      Image: application, 
      ImageTwo: manage,
      Title: "Application Integration Services",
      Desc: "Provides seamless connectivity across your technology ecosystem, ensuring that all applications and data work in harmony. We specialize in creating intuitive user experiences through our UI/UX front-end development, combined with robust integration solutions that streamline workflows and enhance operational efficiency.",
      Link: "/application-integration-services",
    },
    {
      Image: identity,
      ImageTwo:manage,
      Title: "Infrastructure & Managed Services",
      Desc: "Delivers a robust suite of services, including IT consulting, NOC/SOC support, network and telephony solutions, managed IT services, and IT procurement. These offerings enhance operational efficiency, strengthen security, and ensure seamless connectivity, empowering organizations to achieve their business objectives while maintaining compliance and reducing risks.",
      Link: "/it-consulting",
    },
    {
      Image: staffing,
      ImageTwo: eye_one,
      Title: "Staffing & Outsourcing Services",
      Desc: "Empower organizations to streamline operations and focus on core competencies. Our tailored staffing solutions connect you with skilled professionals, while our outsourcing services manage non-core functions, enhancing efficiency and reducing costs. Together, we drive innovation and optimize resources for sustainable growth and success.",
      Link: "/staffing-services",
    },
    
    
  ];
  return (
    <section className="sec_pad" aria-labelledby="service-heading">
      <Container>
        <Row className="row-gap-small p-0
        ">
          <Col md={12} sm={12} xs={12}>
            <div className="service_full">
              <div className="title" id="service-heading">
                {/* Integrated Technology Services */}
              </div>
              <div className="sub_title">What we Offer</div>
              <div className="cnt">
                <p>
                  Securoak leads the way in digital security and IT innovation, offering tailored solutions that enhance performance, fortify resilience, and drive measurable outcomes. We empower businesses to thrive in an ever-evolving digital landscape with a strong emphasis on cybersecurity and AI.
                </p>
                
               
              </div>
            </div>
          </Col>
        </Row>

        <Row className="row-gap-small2 p-0">
          {Data.map((value, index) => (
            <Col md={12} sm={12}  lg={6} key={index}>
              <div className="service_section_box" data-aos="fade-up" >
                <div
                  className="service_section_box_inner"
                  style={{
                    "--image-url": `url(${value.Image})`,
                    "--image-two-url": `url(${value.ImageTwo})`,
                  }}
                >
                  <div className="service_section_box_front">
                    <div className="title_only">
                      <div className="title">{value.Title}</div>
                    </div>
                  </div>
                  <div className="service_section_box_back">
                    <div className="text_color_white">
                      <div className="cnt">{value.Desc}</div>
                      
                    
                      <div class="button-container">
     <Link to={value.Link} className="cta-button"
      onClick={(e) => {
        setTimeout(() => {
          const element = document.getElementById(value.Id); // Make sure the ID matches
          if (element) {
            element.scrollIntoView({ behavior: "smooth" });
          }
        }, 0);
      }}
     
     
     >Read More</Link>
      </div>
    
                     
                     
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
}
