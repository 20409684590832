import React from 'react';
import './WhoWeAre.scss'; // SCSS styling for the component
import largeImage from './whoweare2.jpg'; // Import the image file
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
const WhoWeAre = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 }); // Initialize AOS with animation duration
  }, []);
  return (
    <section className="who-we-are">
    <Container className="content-wrapper">
      <Row className="align-items-center">
        {/* Text Content */}
        <Col md={12} sm={12} data-aos="fade-right" className="text-content">
          <h1>Who We Are</h1>
          <p>
            Securoak leads in advanced digital security, blending AI and expert-driven solutions to tackle complex cybersecurity
            and infrastructure challenges. Our services in Identity and Access Management, application integration, and managed
            IT drive performance, resilience, and compliance - enabling organizations to innovate securely.
          </p>
          <p>
            With tailored API development and end-to-end support through our Network and Security Operations Centers, Securoak
            enhances agility, mitigates risk, and empowers your business to thrive in today’s digital landscape.
          </p>
        </Col>
        {/* Image Container */}
        <Col md={12} sm={12} data-aos="fade-up" className="image-container">
          <img src={largeImage} alt="Who We Are" className="img-fluid" />
        </Col>
      </Row>
    </Container>
  </section>
  );
};

export default WhoWeAre;
