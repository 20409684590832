import React, { useEffect } from "react";
import "./home.scss";
import AOS from "aos";
import "aos/dist/aos.css"; // Import AOS styles
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom"; // Ensure you have this import

export default function Home({ data }) {

  useEffect(() => {
    AOS.init({ duration: 1000, once: true });
    const starContainer = document.querySelector(".star-container");
    if (!starContainer) return; // Exit if the container is not found
    const starCount = 50;

    for (let i = 0; i < starCount; i++) {
      const star = document.createElement("div");
      star.className = "star";
      const size = Math.random() * 3 + 1;
      star.style.width = `${size}px`;
      star.style.height = `${size}px`;
      star.style.top = `${Math.random() * 100}%`;
      star.style.left = `${Math.random() * 100}%`;
      star.style.animationDuration = `${Math.random() * 15 + 15}s`;
      star.style.animationDelay = `${Math.random() * 10}s`;
      starContainer.appendChild(star);
    }
  }, []);

  return (
    <section>
      <div className="banner_bg">
        <div className="star-container" aria-hidden="true">
          <Container>
            <Row>
              <Col md={8} className="height_adjust">
                {/* Title 1 */}
                <h1 className="sub_title_one" style={{ color: "#007bff" }}>
                Securing Your Digital World
                </h1>

                {/* Title 2 (Handle if data is available or not) */}
                <h2 className="title_one" data-aos="fade-up" data-aos-delay="100">
                   Elevate Your IT Landscape with
                </h2>

                <h2 className="title_two typing-animation" data-aos="fade-up" data-aos-delay="200">
                Securoak’s AI-Driven Cyber Solutions
                </h2>

                <div className="two_buttons" data-aos="fade-up" data-aos-delay="450">
                  <Link to="/upcoming-events" aria-label="Learn more about our services">
                    <div className="service"> Upcoming Events</div>
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </section>
  );
}
