import React, { useEffect, useState } from 'react';
import './UpcomingEvents.scss';
import event1 from './event1.jpg'; // Import the image file
import event2 from './event2.jpg'; // Import the image file
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Header from '../../header/header';
import Footer from '../../footer/footer';
import banner from "./eventsbanner.jpg";

const UpcomingEvents = () => {

  // const [events, setEvents] = useState([]);
  // const [loading, setLoading] = useState(true);
  // const [error, setError] = useState(null);

  // useEffect(() => {
  //   const api = "https://673d82190118dbfe86077e7a.mockapi.io/events";

  //   const fetchEvents = async () => {
  //     try {
  //       const response = await fetch(api);
  //       if (!response.ok) {
  //         throw new Error('Network response was not ok');
  //       }
  //       const data = await response.json();
  //       setEvents(data);
  //     } catch (error) {
  //       setError(error.message);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchEvents();
  // }, []);

  // if (loading) {
  //   return <p>Loading...</p>;
  // }

  // if (error) {
  //   return <p>Error: {error}</p>;
  // }

  return (
    <section className="upcoming-events" id="upcoming-events">
      <Header identityPage={true} />

      {/* Banner Section */}
      <div className="identity-image-container">
        <img
          src={banner}
          alt="Event Banner"
          className="identity-page-image"
        />
        <div className="overlay-text">
          <h1>Our Upcoming Events</h1>
          <p>
            Home <i className="fa-solid fa-greater-than banner-icon"></i> Upcoming Events
          </p>
        </div>
      </div>

      {/* Event Details */}
      <Container className="event-container">
        <Row className="event-row">
          {/* Event 1 */}
          <Col md={6} className="event-col">
            <div className="event-card">
              <Row>
                <Col md={6} className="event-img-col">
                  <img src={event2} alt="Event 1" className="event-img" />
                </Col>
                <Col md={6} className="event-text-col">
                  <h3 className="event-title">AUSTRALIAN CYBER CONFERENCE</h3>
                  <p className='date'>
                    <strong><i className="fa-solid fa-calendar"></i> Date:</strong> 26 - 28 NOV - 2024
                  </p>
                  <p className='venue'>
                    <strong><i className="fa-solid fa-map-marker-alt"></i> Venue:</strong> Melbourne Convention & Exhibition Center
                  </p>
                  <p className='booth'>
                    <strong><i className="fa-solid fa-handshake"></i> Meet us at:</strong> Booth 41
                  </p>
                </Col>
              </Row>
            </div>
          </Col>

          {/* Event 2 */}
          <Col md={6} className="event-col">
            <div className="event-card">
              <Row>
                <Col md={6} className="event-img-col">
                  <img src={event1} alt="Event 2" className="event-img" />
                </Col>
                <Col md={6} className="event-text-col">
                  <h3 className="event-title">AUSTRALIAN CYBER CONFERENCE</h3>
                  <p>
                    <strong><i className="fa-solid fa-calendar"></i> Date:</strong> 17 - 19 MARCH 2025
                  </p>
                  <p>
                    <strong><i className="fa-solid fa-map-marker-alt"></i> Venue:</strong> Melbourne Convention & Exhibition Center
                  </p>
                  <p>
                    <strong><i className="fa-solid fa-handshake"></i> Meet us at:</strong> Upcoming
                  </p>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      
      <Footer />
    </section>

    
  );
};

export default UpcomingEvents;
