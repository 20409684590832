import React from "react";
import "./loader.scss";

export default function Loader() {
  return (
    <div className="load_bg">
      <div class="loader"></div>
    </div>
  );
}
