import React from 'react'
import "./It-Consulting.css"
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import journey from '../innerdropdowns/journey.jpg';
import { Container, Row, Col, Card, ListGroup } from "react-bootstrap";
import { FaUsers, FaChartLine, FaCogs, FaHandshake, FaLock } from "react-icons/fa";
import banner from '../WorkforceIdentity/workforce2.jpg';
import partner from "../../components/assests/partner.jpg"

import { FaShieldAlt, FaRegLightbulb,FaClipboardCheck,FaRocket, FaCloudUploadAlt,FaTachometerAlt, FaLayerGroup,  } from 'react-icons/fa';
import 'aos/dist/aos.css'; // Import AOS styles
import AOS from 'aos'; // Import AOS
import  { useEffect } from 'react';


const ItConsulting = () => {

  useEffect(() => {
    AOS.init({ duration: 1000, once: true }); // Initialize AOS
  }, []);


  const journeySteps = [
    { icon: <FaUsers />, title: "Consultation and Discovery", description: "Assessment:We conduct a thorough analysis of your current IT environment to identify areas for improvement, focusing on security vulnerabilities." },
    { icon: <FaChartLine />, title: "Strategic Planning", description: "Strategy Development: Collaborating with your team, we create a comprehensive IT strategy that drives efficiency and innovation while safeguarding sensitive data." },
    { icon: <FaCogs />, title: "Implementation", description: "Implementation: : Our experts oversee the deployment of AI-driven solutions, ensuring seamless integration with your existing systems and robust cybersecurity measures." },
    { icon: <FaHandshake />, title: "Ongoing Collaboration", description: "Continuous Improvement:  We provide ongoing support and optimization to adapt to your evolving needs, keeping pace with emerging threats and technological advancements." },
   
  ];

  return (
    
    <>
     <Header identityPage={true} />
           {/* Image and Overlay Section */}
           <div className="identity-image-container">
        <img
          src={banner}
          alt="A depiction of the company's philosophy"
          className="identity-page-image"
        />
        <div className="overlay-text">
          <h1>IT Consulting </h1>
          <p>Home <i className="fa-solid fa-greater-than benner-icon"></i> Services <i className="fa-solid fa-greater-than benner-icon"></i> Infrastructure & Managed Services <i className="fa-solid fa-greater-than benner-icon"></i> IT Consulting</p>
        </div>
      </div>

      <section>




      <div class="it-discription" id='it-consulting'>
  <p data-aos="zoom-out-up">
    Securoak provides an extensive range of IT services designed to optimize organizational performance and enhance cybersecurity. Our offerings include IT consulting, NOC/SOC support, network and telephony solutions, managed IT services, and IT procurement. By integrating advanced AI technologies, we streamline operations, bolster security measures, and ensure compliance, empowering businesses to navigate the complexities of today’s digital landscape while mitigating risks effectively. With a focus on delivering tailored solutions, Securoak enables organizations to achieve their strategic objectives and maintain seamless connectivity.
  </p>
</div>


<h4 className="feature-heading">Key Differentiators of (WIAM)</h4>
      <Row className="align-items-center Features features2">
      {/* Feature Cards on the Left */}
      <Col md={12}>
        <div className="keyfeatures-container  keyfeatures-container2 container-fluid">
          <div className="features-list">
            <Row className="identify-cards" >
              {/* Feature 1 */}
              <Col md={12} className="feature-point" data-aos="fade-right">
                <div className="feature-icon">
                
                </div>
                <div className="feature-text">
                <FaShieldAlt size={30} className="feature-icon"/><strong>Tailored Solutions::</strong><br />
                  We customize our services to align with your specific business goals, ensuring optimal results while enhancing security.
                </div>
              </Col>

              {/* Feature 2 */}
              <Col md={12} className="feature-point" data-aos="fade-left">
                <div className="feature-icon">
                 
                </div>
                <div className="feature-text">
                <FaRegLightbulb size={30} className="feature-icon"/><strong>Expert Guidance: </strong><br />
                  Our seasoned consultants bring deep industry knowledge, helping you navigate complex technology landscapes, including AI integration and cybersecurity best practices.
                </div>
              </Col>

            </Row>
          </div>
        </div>
      </Col>

    
    </Row>

   

    <Card className="mb-4 potential-card m-3" data-aos="fade-up">
  <h2 className="text-center pt-3 journey-title">Journey with Us</h2>

  <Card.Body>
    <Row className="align-items-center">
      {/* Content Column */}
      <Col md={12}  sm={12} lg={6} className="content-column mb-4 mb-md-0">
        <ListGroup variant="flush">
          {journeySteps.map((item, index) => (
            <ListGroup.Item key={index} className="list-item">
              <span>{item.description}</span>
            </ListGroup.Item>
          ))}
        </ListGroup>
      </Col>

      {/* Image Column */}
      <Col md={12}  sm={12} lg={6}  className="image-column text-center">
        <img src={journey} alt="Journey with Us" className="img-fluid big-image" />
      </Col>
    </Row>
  </Card.Body>
</Card>


<section className="why-partner-section" data-aos="fade-up">
  <div className="container">
    {/* Section Top Content */}
    <div className="why-partner-top text-center mb-4">
      <h2 className="why-partner-title">Why Partner with Securoak?</h2>
      <p className="why-partner-description">
        Transform your IT strategy with Securoak, leveraging AI and cybersecurity to drive sustainable growth for your organization.
      </p>
    </div>

    <Row className="align-items-center">
   
      <Col md={12} sm={12} lg={6} className="text-center mb-4 mb-md-0">
        <img src={partner} alt="Securoak" className="img-fluid why-partner-image" />
      </Col>

      {/* Features Column */}
      <Col md={12} sm={12} lg={6}>
        <ul className="partner-features-list">
          <li>
            <strong>Proven Expertise:</strong> Our track record in delivering successful IT consulting projects, combined with our focus on security, speaks for itself.
          </li>
          <li>
            <strong>Innovative Solutions:</strong> We leverage the latest technologies and best practices in AI and cybersecurity to enhance your IT capabilities.
          </li>
          <li>
            <strong>Long-Term Partnership:</strong> We are committed to your success, providing continuous support and strategic advice to help you thrive in a digital-first, secure environment.
          </li>
        </ul>
      </Col>
    </Row>
  </div>
</section>

    



</section>
<Footer />
    </>
  )
}

export default ItConsulting;
