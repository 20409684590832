import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./video.scss";
import svgimg from "../video/undraw_online_media_re_r9qv.svg";
import img1 from "./up1.jpg"
import img2 from "./up2.jpg"
import img3 from "./up3.jpg"
import img4 from "./up5.jpg"
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import video from "../video/Securoak_Services-Final (2).mp4";

export default function Video() {

  
  return (
    <>
    <div className="videosec">
      <h2>Upcoming Events</h2>
      <div className="card">
        <div className="card-content">
          <Container>
            <Row className="align-items-center">
              {/* Text Content */}
              <Col md={6} sm={12} className="card-text">
                <h2>AUSTRALIAN CYBER CONFERENCE</h2>
                <h4>26 - 28 NOVEMBER 2024</h4>
                <h4>Melbourne Convention & Exhibition Center</h4>
                <h4>Booth 41</h4>
                <div className="button-container">
                  <Link
                    to="/upcoming-events"
                    className="cta-button"
                    onClick={(e) => {
                      setTimeout(() => {
                        const element = document.getElementById("upcoming-events");
                        if (element) {
                          element.scrollIntoView({ behavior: "smooth" });
                        }
                      }, 0);
                    }}
                  >
                    Know More
                  </Link>
                </div>
              </Col>

              {/* Video Content */}
              <Col md={12} sm={12} className="card-video">
                <video
                  width="100%" // Makes the video responsive
                  height="315"
                  controls
                  autoPlay
                  loop
                  muted
                >
                  <source src={video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Col>
            </Row>

            {/* New Row with 3 Images */}
            
          </Container>
        </div>
      </div>
    </div>


    <div className="carousel-body">
  <div className="carousel">
    <div className="wrap">
      <img src={img1} alt="" />
      <img src={img2} alt="" />
      <img src={img3} alt="" />
      <img src={img4} alt="" />
      <img src={img1} alt="" />
      <img src={img2} alt="" />
      <img src={img3} alt="" />
      <img src={img4} alt="" />
      <img src={img1} alt="" />
      <img src={img2} alt="" />
      <img src={img3} alt="" />
      <img src={img4} alt="" />
      <img src={img1} alt="" />
      <img src={img2} alt="" />
      <img src={img3} alt="" />
      <img src={img4} alt="" />
      <img src={img1} alt="" />
      <img src={img2} alt="" />
      <img src={img3} alt="" />
      <img src={img4} alt="" />
    </div>
  </div>
</div>


    </>
  );
}
